import React from "react"
// import { Link } from 'gatsby'

import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import Banner from "../components/Banner/Banner"

export const errorPage = graphql`
  query ErrorPageQuery {
    sanityPage(slug: {current: {eq: "404"}}) {
      pageBanner {
        title
        image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
	  }
  }
`

const NotFoundPage = ({data}) => (
  <Layout>
    <SEO title="404: Not found" />
    <div>
      <Banner 
				heading={ data.sanityPage.pageBanner.title }
				image={ data.sanityPage.pageBanner.image.asset.fluid }
				bannerAlt="banner--lg"
			/>
    </div>
  </Layout>
)

export default NotFoundPage;
